import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { FormattedMessage } from "react-intl";
import Button from "../buttons/button";
import { router, usePage } from "@inertiajs/react";

export default function CookieNotice() {
    const { cookiesAccepted } = usePage().props;
    return (
        <Dialog
            open={!cookiesAccepted}
            onClose={() => null}
            className="relative z-[999]"
        >
            <div className="fixed inset-0 bg-gray-500/75" aria-hidden="true" />
            <div className="fixed inset-0 flex w-screen items-center justify-center p-6">
                <DialogPanel className="w-full sm:max-w-lg rounded bg-white p-6">
                    <div className="flex items-center">
                        <DialogTitle className="text-3xl leading-6 text-gray-900 font-bold ml-2">
                            <FormattedMessage id="We use cookies" />
                        </DialogTitle>
                    </div>
                    <div className="ml-2 pt-4">
                        <FormattedMessage id="We only use Essential cookies that are necessary for features which are essential to your use of our site or services, such as account login, authentication, and site security. Learn more in our" />{" "}
                        <a
                            href={route("privacy-policy")}
                            tabIndex={2}
                            className="underline underline-offset-4"
                        >
                            <FormattedMessage id="Privacy Policy" />
                        </a>
                    </div>
                    <div className="sm:flex sm:flex-row-reverse mt-8 ml-2">
                        <Button
                            tabIndex={1}
                            color="black"
                            onClick={() =>
                                router.post(route("home.acceptCookies"))
                            }
                        >
                            <FormattedMessage id="I'm OK with this!" />
                        </Button>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    );
}
